import React, { useState, useEffect } from 'react'
import { makeStyles, useTheme } from '@mui/styles'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { useAuth } from "./api/authStore"
import TextField from '@mui/material/TextField'
import { Typography } from '@mui/material'
import FormControl from '@mui/material/FormControl'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import InputLabel from '@mui/material/InputLabel'
import OutlinedInput from '@mui/material/OutlinedInput'
import Divider from '@mui/material/Divider'

const useStyles = makeStyles((theme) => ({
    mainDiv:{
        alignItems:'center',
        textAlign:'center',
        justifyContent: 'center',
    },
    divRow: {
        display: 'flex',
        alignItems:'center',
        textAlign:'center',
        justifyContent: 'center',
     },
     columnDiv: {
        flex: '50%',
     }
}))

const map = (type) => {
    switch(type){
        case 'BTC':
            return {currency: type, network: 'btc', }
        case 'LTC':
            return {currency: type, network: 'ltc', }
        case 'USDT':
            return {currency: type, network: 'tron',}
        case 'ETH':
            return {currency: type, network: 'arbitrum',}
        case 'XMR':
            return {currency: type, network: 'xmr'}
        default:
            return {currency: 'btc', network: 'btc'}
    }
}

const PaymentDialog = (props) => {


    const { user, getAccessTokenSilently, setToken, logout } = useAuth()

    const [error, setError] = React.useState(null)
    const [currency, setCurrency] = React.useState('')
    const [amount, setAmount] = React.useState(5)

    const handleClose = () => {
        props.setOpentPaymentDialog(false)
        setError(null)
    }


    const handleAdd = async() => {
        try {
            const response = await props.apiClient.setToken(getAccessTokenSilently()).chargeRequest(currency, amount)

            window.open(response.url, "_blank")
            // console.log(response)
            props.setOpentPaymentDialog(false)
        } catch (e) {
            setError('Try again, please.')
            // console.log(e)
        }
    }

    const handleSelectCurrency = (event) => {
        setCurrency(map(event.target.value))
    }

    const handleChangeAmount = (e) => {
        const amount = parseFloat(e.target.value!==""?e.target.value:0)
        
        // if(amount <= 0){
        //     setAmount(5)
        // } else {
            setAmount(amount)
        // }
    }

    return (
      <Dialog
        open={props.open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{sx:{width:'450px'}}}
      >
        <DialogTitle id="alert-dialog-title">
         Payment
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-desc">
          <div>
            <Typography variant="body1" gutterBottom>
                please allow popup at your browser
            </Typography>
            <Typography variant="body1" gutterBottom>
                we accept only next crypo currencies: BTC, LTC, USDT, XMR
            </Typography>
            <FormControl sx={{ m: 1, minWidth: 120 }}>
                <TextField id="outlined-basic" 
                           label="Amount USD" 
                           variant="outlined" 
                           inputProps={{sx:{width:160}}}
                           onChange={handleChangeAmount}
                           value={amount}
                />
            </FormControl>
            {error !== null &&
                <div style={{color:'red'}}>{error}</div>
            }     
            </div>
            
            <Divider/>
            <Typography variant="body1" gutterBottom>
                please remember that refunds cannot be made back in currency
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button  color='success' variant='outlined' sx={{width: '120px'}} onClick={handleAdd}>Add</Button>
        </DialogActions>
      </Dialog>
  )
}

export default PaymentDialog