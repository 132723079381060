import * as React from 'react'
import { makeStyles, useTheme } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
    main: {
        width: '75%',
        height: '100vh',
    },
    header: {
        // height: '72px',
        margin: '32px',
        fontWeight: 600,
    },
    content: {
        height: '72px',
        // marginLeft: '18px',
        // marginRight: '32px',
        height: '400px',
        // border: '1px solid',
        borderRadius: '16px',
        justifyContent: 'center',
        textAlign:'center',
        paddingTop: '200px',
        fontSize:' 30px',
    },
}))

const PaymentSuccess = (props) => {
    const classes = useStyles()

    return (
        <div className={classes.main}>
        <div className={classes.header}>Payment Status</div>
        <div className={classes.content}><span>Your payment was successfull.</span></div>
        </div>
    )
}

export default PaymentSuccess